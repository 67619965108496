import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Row, Column, Text, Image } from 'components/atoms';

const TestimonialSlide = ({ data, className }) => {
  const { featured_media, acf } = data;
  const { source_url, media_details } = featured_media || {};
  const { company, name, position, text } = acf;

  return (
    <div className={cn('testimonial-slide', 'swiper-slide', [className])}>
      <Row>
        <Column
          lg="3"
          col="8"
          className={`offset-lg-1 offset-2`}
          onMouseDown={e => e.preventDefault()}
          onContextMenu={e => e.preventDefault()}
        >
          <div className="testimonial-slide__picture">
            {source_url && (
              <Image
                src={source_url || ''}
                alt={company}
                width={media_details?.width}
                height={media_details?.height}
                className="testimonial-slide__picture__image"
              />
            )}
          </div>
        </Column>
        <Column lg="7" className="testimonial-slide__item">
          <Text className="testimonial-slide__item__text body-lg">{text}</Text>
        </Column>
      </Row>
      <Row>
        <Column lg="7" className="offset-lg-4">
          <Text className="testimonial-slide__author body-sm body-sm_thin">
            {`${name}, ${position}`}
          </Text>
          <Text className="testimonial-slide__author body-sm body-sm_thin">
            {company}
          </Text>
        </Column>
      </Row>
    </div>
  );
};

TestimonialSlide.defaultProps = {
  className: '',
};

TestimonialSlide.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default TestimonialSlide;
