import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Swiper from 'swiper';

import 'swiper/css/swiper.css';
import './index.scss';

import { Section, Container, Icon } from 'components/atoms';

import TestimonialSlide from 'components/molecules/TestimonialSlide';

const Testimonials = ({ data, testimonialsData, className, customMargin }) => {
  const { testimonials } = data;

  const testimonialsMap =
    testimonials &&
    testimonials.map(({ wordpress_id: testimonialId }, index) => {
      const testimonial = testimonialsData.find(
        ({ wordpress_id: itemId }) => testimonialId === itemId
      );

      return <TestimonialSlide data={testimonial} key={index} />;
    });

  const isSliderActive = testimonialsMap && testimonialsMap.length > 1;

  const initSwiper = () => {
    new Swiper('.testimonials__swiper', {
      speed: 400,
      navigation: {
        nextEl: '.testimonials__swiper-button-next',
        prevEl: '.testimonials__swiper-button-prev',
        disabledClass: 'testimonials__swiper-button-disabled',
      },
      noSwiping: !isSliderActive && true,
      noSwipingClass: !isSliderActive && 'swiper-slide',
    });
  };

  useEffect(() => {
    initSwiper();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section
      className={cn(
        'testimonials',
        customMargin && 'testimonials--custom-margin',
        [className]
      )}
    >
      <Container>
        {testimonialsMap && (
          <div className="testimonials__swiper swiper-container">
            <div className="swiper-wrapper">{testimonialsMap}</div>

            <div className="testimonials__swiper-button-prev">
              <Icon variant="slide-prev" />
            </div>
            <div className="testimonials__swiper-button-next">
              <Icon variant="slide-next" />
            </div>
          </div>
        )}
      </Container>
    </Section>
  );
};

Testimonials.defaultProps = {
  className: '',
};

Testimonials.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};
export default Testimonials;
